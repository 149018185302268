import { render, staticRenderFns } from "./VideoCard.vue?vue&type=template&id=92ab3b34&scoped=true&"
import script from "./VideoCard.vue?vue&type=script&lang=js&"
export * from "./VideoCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92ab3b34",
  null
  
)

export default component.exports