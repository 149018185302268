<template>
    <v-card flat elevation="0" class="pa-6">
        <v-container class="pa-0">
            <v-row class="pa-0">
                <v-col cols="3">
                    <div class="d-flex justify-start align-start flex-column w-100">
                        <span class="primary--text font-size-18">{{
                            $t('resources.categories')
                        }}</span>
                        <v-radio-group v-model="courseSelect" class="pa-0 ma-0 mt-2 w-100">
                            <v-radio
                                v-for="(category, index) of categories"
                                :key="index"
                                :label="`${category.name}`"
                                :value="category.value"
                                class="radio-content primary--text py-0 w-100"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </v-col>
                <v-col cols="9">
                    <v-row v-if="videos.length">
                        <v-col md="4" sm="12" lg="4" v-for="(video, index) of videos" :key="index">
                            <VideoCard :video-data="video"></VideoCard>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" class="text-center">
                            <span class="primary--text font-size-24">{{
                                $t('resources.not_videos')
                            }}</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import VideoCard from './core/VideoCard'
import webinarsService from '../../services/webinars'

export default {
    name: 'Training',
    components: { VideoCard },
    computed: {
        categories() {
            return [
                { value: 0, name: this.$t('resources.all') },
                // { value: 1, name: this.$t('resources.student_tutorial') },
                { value: 2, name: this.$t('resources.teacher_tutorial') },
                { value: 3, name: this.$t('resources.recorded_webinar') },
            ]
        },
        videos() {
            if (this.courseSelect !== 0) return this.webinars.filter(({ typeValue }) => typeValue === this.courseSelect)
            return this.webinars
        },
    },
    data: () => ({
        courseSelect: 0,
        webinars: [],
    }),
    mounted() {
        webinarsService.getWebinars().then((response) => {
            this.webinars = response
        })
    }
}
</script>

<style scoped>
.radio-content {
    border-radius: 5px;
}
</style>
