<template>
    <div>
        <v-overlay v-model="dialog" color="primary" style="z-index: 6"></v-overlay
        ><v-dialog
            v-model="dialog"
            content-class="d-flex elevation-0 position-relative"
            transition="dialog-bottom-transition"
            persistent
            width="900"
            elevation="0"
            hide-overlay
        >
            <v-btn
                small
                icon
                color="primary"
                elevation="2"
                class="primary lighten-2 white--text position-absolute btn-corner"
                @click="exitCard"
            >
                <v-icon class="white--text">mdi-close</v-icon>
            </v-btn>
            <v-card
                elevation="0"
                color="white"
                class="position-relative cursor-pointer white overflow-hidden"
                v-if="dialog"
            >
                <v-container>
                    <v-row class="px-5">
                        <v-col cols="12">
                            <iframe
                                id="player"
                                type="text/html"
                                width="100%"
                                height="460"
                                :src="link"
                            ></iframe>
                        </v-col>
                        <v-col cols="12" class="pt-0">
                            <v-card-title class="pa-0 grey--text">{{ name }}</v-card-title>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    name: 'VideoModal',
    props: {
        link: { type: String, default: '' },
        name: { type: String, default: 'Untitle' },
        dialog: { type: Boolean, default: false },
    },
    methods: {
        exitCard() {
            this.$emit('close-modal')
        },
    },
}
</script>

<style scoped>
.btn-corner {
    z-index: 1;
    top: 1vh;
    right: 1vh;
}
</style>
