<template>
    <div>
        <v-card
            :loading="loading"
            elevation="0"
            class="cursor-pointer mx-auto border-0"
            max-width="374"
            @click="showModal"
        >
            <v-img height="150" :src="image"></v-img>

            <v-card-title class="font-size-16 px-0">{{ videoData.name }}</v-card-title>
        </v-card>
        <VideoModal
            :dialog="showFrame"
            :link="videoData.url"
            :name="videoData.name"
            @close-modal="showModal"
        ></VideoModal>
    </div>
</template>

<script>
import VideoModal from '../modals/VideoModal'
export default {
    name: 'VideoCard',
    components: { VideoModal },
    props: {
        videoData: { type: Object, default: () => null },
    },
    data: () => ({
        loading: false,
        image: require('../../../assets/img/video/video.png'),
        showFrame: false,
    }),
    methods: {
        showModal() {
            this.showFrame = !this.showFrame
        },
    },
}
</script>

<style scoped></style>
